.calendar-day {
  cursor: pointer;
  text-align: center;
  padding: 10px;
}

.calendar-day.selected {
  background-color: #007bff;
  color: white;
}
.apexcharts-toolbar{
  margin-right: 24px;
}
.calendar .table {
  width: 100%;
}
.reportBtn-group .btn{
  margin-left: 16px;
}

.padding8px{
  padding: 8px;
}
.empty {
  background-color: #f8f9fa;
}

table{
  background-color: #ffffff;
}
.App {
  text-align: center;
}
 .reportBtn-group > button{
  padding: 0px!important;
}
.reportBtn-group button img{
  width: 35px;
}
.form-check.form-check-solid .form-check-input:not(:checked){
  background-color: #ccc!important;
}

.breadcrumb-item a {
  color: #000;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.reportTable{
  border-collapse: collapse;
  margin: 20px auto ; 
  font-family: Arial, sans-serif;
  font-size: 12px;
}
.reportSearch label {
  font-size: 16px;
  font-weight: bold;
}
.reportSearch button { 
  font-weight: bold;
}
.reportTable td:nth-child(2) {
  text-align: left;
}
.reportCaption{
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: bold; 
  pointer-events: auto;
  background-color: #666666;
  border-top: 1px solid #FFFFFF;
  text-indent: 0px;
  vertical-align: middle;
  text-align: center;  
  font-family: Arial;
  color: #FDFDEC;
  font-size: 16px;
  line-height: 1.1499023;
  font-weight: bold;
  padding:15px;
}
table th{
   border: 1px solid #fff;
   text-align: center;
   padding: 2px;
   background-color: #1E1E86!important;
   color: #fff;
}
.reportTable td{
  text-align: center;
  border: 1px solid #999;
  text-align: center;
  padding: 2px; 
  background-color: #ECECEC;  
}

.card {
  margin: 16px;
}
.reportNavigation{
  width: 22%;
  border-right: 1px solid #ebddf2;
  display: inline-flex;
  background-color: #ffffff;
  height: 100%;
}
.reportBody{
  overflow-y: auto;
  width: 75%; 
  margin: auto;
}
.topBrandReport{
  display: flex;
  overflow-y: auto;
  height: 100%;
}

.reportSearch{ 
  height: auto;
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 350px;
}
.reportSearch .text-danger{
  margin-left: 5px;
}
.reportDatePickerFrom , .reportDatePickerTo {
  width: 122px;
}
.reportDatePickerFrom , .reportDatePickerTo , .reportDatePickerBtn{ 
  float: left;
  margin-left: 5px;
}
.card-toolbar button{
  margin-left: 5px;
}
.card i{
  font-size: 16px;
  color: #000;
}
.reportSearch input{ 
  height: 8px!important;
  font-size: 12px;
} 
.table.gy-7 th, .table.gy-7 td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

table th{
  border: 1px solid #fff;
  text-align: center;
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;

  background-color: #000!important;
  color: #fff;
}
.todaysStatusTable td{
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.reportTableView td{
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.pl-0{
  padding-left: 0px !important;
}
.pr-0{
  padding-right: 0px !important;
}
#displayNamesRight{
  text-align: right;
}
.page-link.active, .active > .page-link, .btn.btn-primary{
  background-color: #000;
}

#kt_app_sidebar {
  background-color: #FAFAFA;
}

#kt_app_sidebar a{
  color: #000;
}


.App-link {
  color: #61dafb;
}
.container{
  margin-top: 10px;
}

.text-blue{
  color:#1E1E86;
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
